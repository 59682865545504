import React, {useState, useEffect} from "react";
import {Header} from "./components/header";
import {Features} from "./components/features";
import {About} from "./components/about";
import {Services} from "./components/services";
import {Gallery} from "./components/gallery";
import {Team} from "./components/Team";
import {Contact} from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {Outlet} from 'react-router-dom';
import {Footer} from "./components/footer";
import Navigation from "./components/navigation";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


const HeaderFooterLayout = () => (
    <div style={{height:'100%', display:'flex', flexDirection: 'column'}}>
        <Navigation/>
        <div style={{flexGrow: 1}}>
            <Outlet/>
        </div>
        <Footer/>
    </div>
);

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<HeaderFooterLayout/>}>
                    <Route path="/">
                        <Route path={"/"}  element={<Header data={landingPageData.Header} />}/>
                        <Route path="/features" element={<Features data={landingPageData.Features}/>}/>
                        <Route path="/about" element={<About data={landingPageData.About}/>}/>
                        <Route path="/services" element={<Services data={landingPageData.Services}/>}/>
                        <Route path="/portfolio" element={<Gallery data={landingPageData.Gallery}/>}/>
                        <Route path="/team" element={<Team data={landingPageData.Team}/>}/>
                        <Route path="/contact" element={<Contact data={landingPageData.Contact}/>}/>
                    </Route>
                </Route>
                {/*<Route path="*" element={<Navigation/>}/>*/}
            </Routes>
        </BrowserRouter>
    );
};

export default App;
