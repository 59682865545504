import React from "react";
import {FaDatabase, FaDiceD20, FaIndustry} from "react-icons/fa";
import {FaComputer} from "react-icons/fa6";

const data = [
    {
        "icon": FaDatabase,
        "name": "Intelligent Databases",
        "text": ""
    },
    {
        "icon": FaComputer,
        "name": "AI Control & Management of Production",
        "text": ""
    },
    {
        "icon": FaIndustry,
        "name": "Smart Regulation Systems",
        "text": ""
    },
    {
        "icon": FaDiceD20,
        "name": "Accelerated Resolution Methods In Modeling",
        "text": ""
    },
    // {
    //     "icon": "fa fa-plane",
    //     "name": "Lorem ipsum dolor",
    //     "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    // },
    // {
    //     "icon": "fa fa-pie-chart",
    //     "name": "Consectetur adipiscing",
    //     "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
    // }
]

export const Services = (props) => {

    return (
        <div id="services" className="text-center" style={{height:'100%'}}>
            <div className="container">
                <div className="section-title">
                    <h2>Our Services</h2>
                    <p>
                        We provide a wide range of services to help you achieve your goals.`
                    </p>
                </div>
                <div className="row">
                    {data
                        ? data.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-6">
                                {" "}


                                <i className="fa">
                                    <d.icon/>
                                </i>
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
            </div>
        </div>
    );
};
