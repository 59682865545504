import { useState } from "react";
import React from "react";
import axios from "axios";
import { server_mail } from "../const/param";
import Chat from "./chat";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [error_text, setErrorText] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, message);

    try {
      console.log('Sending email...', server_mail)
      const response = await axios.post(server_mail, {
        client_name: name,
        client_email: email,
        message: message
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        alert('Email sent successfully!');
        setErrorText('');
        console.log('Email sent successfully!');
        clearState();
      } else {
        alert('Failed to send email');
        setErrorText('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
      setErrorText('Failed to send email');
    }
    console.log('Email operation complete.');
  };
  return (
    <div>
      <div id="contact" style={{height: '100%'}}>
        <div className="container">
          <div className={"col-md-12"}>
            <div className="row">
              <div className="section-title">
                <h2>Chat with EXA</h2>
              </div>
            </div>
            <Chat/>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <br/>
                <br/>
                <br/><h2>Get In Touch</h2>
                <p>
                Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                          type="text"
                          value={name}
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                          type="email"
                          value={email}
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                      name="message"
                      value={message}
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>


          <div className={"col-md-12"}>
            <p className={"text-danger"}>{error_text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
