import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

let currentYear = new Date().getFullYear();
export const Footer = (props) => {
    const [bgImage, setBgImage] = useState("");
    const location = useLocation();

    useEffect(() => {
        let bgImage;
        switch (location.pathname) {
            case '/about':
                bgImage = 'url("../img/about.gif")';
                break;
            case '/services':
                bgImage = 'url("../img/background_services.gif")';
                break;
            case '/contact':
                bgImage = 'url("../img/background_contact.gif")';
                break;
            default:
                bgImage = 'url("../img/background_home.gif")';
        }
        setBgImage(bgImage)
    }, [location]);
    return <div id="footer" style={{backgroundImage: bgImage}}>
        <div className="container text-center">
            <p>
                &copy; {currentYear} AKside Corp.
                All Your Smart Solutions In Industry
            </p>
        </div>
    </div>
}
