// src/Chat.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {server_chat_assistant} from "../const/param";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    Button,
    ConversationHeader,
    Avatar
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './MarkDownStyle.css';
import {FaTrash} from "react-icons/fa";

var MarkdownViewer = ({markdown}) => {
    // pseudo code here, depends on the parser
    return <div className={"markdown-container"}>
        <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
};

const Chat = () => {
    const [threadId, setThreadId] = useState(localStorage.getItem('threadId') || '');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isWaitingResponse, setIsWaitingResponse] = useState(false);

    useEffect(() => {
        if (!threadId) {
            createThread().finally(
                () => {
                }
            )
        } else {
            retrieveMessages();
        }
    }, [threadId]);

    const createThread = async () => {
        try {
            const response = await axios.get(`${server_chat_assistant}/create_thread`);
            const {id} = response.data;
            localStorage.setItem('threadId', id);
            setThreadId(id);
        } catch (error) {
            console.error('Error creating thread:', error);
        }
    };

    const sendMessage = async () => {
        let trimmedMessage = newMessage.trim();
        if (trimmedMessage === '') return;
        setNewMessage('')
        try {
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: 'user',
                    text: trimmedMessage,
                    createdAt: new Date().toISOString(),
                },
            ]);
            setIsWaitingResponse(true);

            const response = await fetch(`${server_chat_assistant}/chatbot_send_msg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: trimmedMessage,
                    thread_id: threadId,
                }),
            });

            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: 'EXA',
                    text: '',
                    createdAt: new Date().toISOString(),
                },
            ]);

            if (!response.body) {
                console.error('ReadableStream not supported in this browser.');
                setIsWaitingResponse(false);
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const text = decoder.decode(value, { stream: true });
                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    {
                        role: 'EXA',
                        text: (prevMessages[prevMessages.length - 1].text + text).replace(/【.*】/g, ''),
                        createdAt: new Date().toISOString(),
                    },
                ]);
            }

            retrieveMessages();
            setIsWaitingResponse(false);

        } catch (error) {
            console.error('Error sending message:', error);
            setIsWaitingResponse(false);
        }
    };


    const retrieveMessages = async () => {
        try {
            const response = await axios.post(`${server_chat_assistant}/chatbot_retreive_msg`, {
                thread_id: threadId,
            });
            // response format:
            // check if response.data.data is defined
            const datas_table = response?.data?.data;
            if (datas_table !== undefined && datas_table.length !== 0) {
                const messages = response.data.data.map((msg) => ({
                    id: msg.id,
                    role: msg.role,
                    text: (msg.content[0]?.text?.value || '').replace(/【.*】/g, ''),
                    createdAt: msg.createdAt,
                }));
                // sort messages by createdAt
                messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                setMessages(messages);
            }
        } catch (error) {
            console.error('Error retrieving messages:', error);
        }
    };

    const closeChat = () => {
        localStorage.removeItem('threadId');
        setThreadId('');
        setMessages([]);
    };
    return (
        <div style={{position: "relative", height: "500px"}}>
            <MainContainer
                style={{fontSize: "1.3em", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",}}
            >
                <ChatContainer
                >
                    <ConversationHeader>
                        <Avatar
                            name="EXA"
                            src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
                        />
                        <ConversationHeader.Content
                            info="Your AI Assistant"
                            userName="EXA"
                        />
                        <ConversationHeader.Actions>
                            <Button onClick={closeChat} className="btn btn-lg">
                                <FaTrash/> {' '}
                                Clear Chat
                            </Button>
                        </ConversationHeader.Actions>
                    </ConversationHeader>
                    <MessageList typingIndicator={isWaitingResponse ? <TypingIndicator content="EXA is typing..." /> : null}>
                        {messages.map((msg, index) => (
                            <Message
                                key={index}
                                model={{
                                    payload: <MarkdownViewer markdown={msg.text}/>,
                                    type: 'custom',
                                    // type: 'text',
                                    // message: msg.text,
                                    sentTime: "just now",
                                    sender: msg.role === 'user' ? 'You' : 'EXA',
                                    direction: msg.role === 'user' ? 'outgoing' : 'incoming',
                                }}
                            />
                        ))}
                    </MessageList>
                    <MessageInput
                        placeholder="Type your message here..."
                        value={newMessage}
                        onChange={val => setNewMessage(val)}
                        onSend={sendMessage}
                        disabled={isWaitingResponse}
                        attachButton={false}
                    />
                </ChatContainer>
            </MainContainer>
            {/*<form onSubmit={(e) => e.preventDefault()} className="chat-form">*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        value={newMessage}*/}
            {/*        className={"form-control"}*/}
            {/*        onChange={(e) => setNewMessage(e.target.value)}*/}
            {/*        placeholder="Type your message here..."*/}
            {/*        disabled={isWaitingResponse}*/}
            {/*    />*/}
            {/*    <button type="submit" onClick={sendMessage} className="btn btn-custom btn-lg"*/}
            {/*            disabled={isWaitingResponse}>*/}
            {/*        Send*/}
            {/*    </button>*/}
            {/*    <button onClick={closeChat} className="btn btn-custom btn-lg">*/}
            {/*        Clear Chat*/}
            {/*    </button>*/}
            {/*</form>*/}
        </div>
    );
};

export default Chat;
