import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";

function Navigation() {
    const [bgImage, setBgImage] = useState("");
    const location = useLocation();
    const btn = useRef();

    useEffect(() => {
        let bgImage;
        switch (location.pathname) {
          case '/about':
            bgImage = 'url("../img/about.gif")';
            break;
          case '/services':
            bgImage = 'url("../img/background_services.gif")';
            break;
          case '/contact':
            bgImage = 'url("../img/background_contact.gif")';
            break;
          default:
            bgImage = 'url("../img/background_home.gif")';
        }
        setBgImage(bgImage)
        // fire click button to hide it
        // test if button is visible
        if (btn.current) {
            // test if navbar is collapsed
            if (!btn.current.classList.contains('collapsed')) {
                btn.current.click();
            }
        }

    }, [location]);

    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{backgroundImage: bgImage}}>
            <div className="container">
                <div className="navbar-header">
                    <button
                        ref={btn}
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <Link to="/" className="navbar-brand page-scroll">
                        AKside Corp
                    </Link>{" "}
                    <img src="img/logo-filial-exotest-group.png"
                         alt="logo"
                         className="navbar-logo"
                    />
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link to="/about" className="page-scroll">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/services" className="page-scroll">
                                Services
                            </Link>
                        </li>

                        <li>
                            <Link to="/contact" className="page-scroll">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;